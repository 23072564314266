<template>
  <div class="row card-panel red accent-2 white-text">
    <p class="flow-text">It appears that some unknown error happened, sorry for the inconvenience.</p>
  </div>
</template>

<script>
export default {
  name: "Error",
}
</script>
